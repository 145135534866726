import http from '@/http'
const URL = '/admin/feed'

export const list = async (params) => {
  const list = (await http.get(URL, { params: params }))
  return list
}

export const show = async (id) => {
  let one = null
  if (id) {
    one = (await http.get(URL + `/${id}?include=user,comments.user`))
  }
  return one
}

export const deletePost = async (id) => http.delete(URL + `/${id}`)
export const deleteComment = async (id) => http.delete(URL + `/comment/${id}`)

export default {
  list,
  show,
  deletePost,
  deleteComment
}
